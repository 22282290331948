import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Login.vue"),
  },
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "about" */ "@/views/Home.vue"),
    children: [
      {
        path: "/dashboard",
        name: "DashBoard",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/DashBoard.vue"),
      },
      {
        path: "/linkdetail",
        name: "LinkDetail",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/LinkDetail.vue"),
      },
      {
        path: "/products",
        name: "Item",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/Item/index.vue"),
      },
      {
        path: "/products/detail",
        name: "ItemDetail",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/Item/detail.vue"),
      },
      {
        path: "/article",
        name: "Article",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/Article/index.vue"),
      },
      {
        path: "/article-detail",
        name: "ArticleDetail",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/Article/detail.vue"),
      },
      {
        path: "/keyword",
        name: "Keyword",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/Keyword/index.vue"),
      },
      {
        path: "/gather",
        name: "Gather",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/Keyword/detail.vue"),
      },
      {
        path: "/category",
        name: "Category",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/Category/index.vue"),
      },
      {
        path: "/category-detail",
        name: "CategoryDetail",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/Category/detail.vue"),
      },
      {
        path: "/category-analysis",
        name: "CategoryAnalysis",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/Category/analysis.vue"
          ),
      },
      {
        path: "/brand",
        name: "Brand",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/Brand/index.vue"),
      },
      {
        path: "/video",
        name: "Video",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/Video/index.vue"),
        children: [
          {
            path: "/script",
            name: "CopyScript",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/views/Video/CopyScript.vue"
              ),
          },
          {
            path: "/scene",
            name: "CopyScene",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/views/Video/CopyScene.vue"
              ),
          },
          {
            path: "/clip",
            name: "CopyClip",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/views/Video/CopyClip.vue"
              ),
          },
       
          {
            path: "/task",
            name: "CopyTask",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/views/Video/CopyTask.vue"
              ),
          },
          {
            path: "/video",
            name: "CopyVideo",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/views/Video/CopyVideo.vue"
              ),
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  if (from.path != "/products/detail" && from.path != "/products") {
    store.state.keepAlive = 0;
    store.state.filter.pageNo = 1;
    store.state.filter.pageSize = 15;
  }
  next();
});
export default router;
