// 该文件记录所有修改全局state的动作
import * as Mutations from "./mutation-types";

export default {
  /**
   * 设置用登录状态
   * @param {Boolean} isLogin
   */
  [Mutations.SET_LOGIN_STATE](state, isLogin) {
    state.isLogin = isLogin;
  },

  [Mutations.SET_CART_TOTAL](state, catTotal) {
    state.catTotal = catTotal;
  },

  /**
   * 设置登录信息
   */
  [Mutations.SET_LOGIN_INFO](state, { id, name, token, isLogin }) {
    console.log("id = ", id);
    console.log("name = ", name);
    console.log("token = ", token);
    console.log("isLogin = ", isLogin);

    state.id = id;
    state.name = name;
    state.token = token;
    state.isLogin = isLogin;
  },

  /**
   * 设置左侧菜单
   */
  // [Mutations.SET_LEFTMENU](state, { leftMenu }) {

  //     console.log('leftMenu = ', leftMenu);

  //     state.leftMenu = leftMenu;

  // },
  /**
   * 设置版本信息
   */

  // [Mutations.SET_MEMBERSHIP](state, { membership }) {
  //     console.log('membership = ', membership);
  //     state.membership = membership
  // },

  [Mutations.SET_ITEM_ID](state, { item_id }) {
    console.log("item_id = ", item_id);
    state.item_id = item_id;
  },
};
