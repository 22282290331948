import { createApp } from 'vue'
import './style.css'
import App from './App.vue'
import router from './router'
import store from './store'

/* 引入 ElementPlus */
import ElementPlus, { ElLoading, ElNotification } from 'element-plus';
import locale from "element-plus/es/locale/lang/zh-cn";
import 'element-plus/dist/index.css'
import '../src/assets/iconfont/iconfont.css'
import '../src/assets/style/common.css'
import '../src/assets/style/index.styl'
import '../src/assets/style/style.css'
import Axios from 'axios'
import EWangEditor from "wangeditor";

const app = createApp(App)
app.config.globalProperties.$notify = ElNotification;

Axios.interceptors.request.use(config => {
    config.headers.Authorization = 'Bearer ' + store.state.token
    return config;
})

Axios.interceptors.response.use(config => {
    if (config.status != 200) {
        router.push({
            name: 'Login'
        });
    }
    return config;
})


import * as ElementPlusIconsVue from '@element-plus/icons-vue'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.use(store).use(router).use(ElementPlus, { locale }).mount('#app')