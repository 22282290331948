<script setup>
import { onMounted } from "vue";
import { SET_LOGIN_INFO } from "@/store/mutation-types";
import { getLocalStorageCache } from "@/utility/cache";
import {  NAME, TOKEN, ISLOGIN } from "@/common/cache-key";
import { useStore } from "vuex";
const store = useStore();
// 强制刷新重新存缓存获取数据
const reloadData = () => {
    const name = getLocalStorageCache(NAME);
    const isLogin = getLocalStorageCache(ISLOGIN);
    store.commit(SET_LOGIN_INFO, {
        name,
        isLogin,
    });
};
onMounted(() => {
    reloadData();
});
</script>

<template>
    <router-view />
</template>


<style scoped lang="stylus"></style>
